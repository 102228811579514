import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCode, faGlobe, faPenNib, faScrewdriverWrench, faLaptopCode, faMobileScreenButton, faArrowCircleRight, faCircleCheck} from '@fortawesome/free-solid-svg-icons';

function Home(){

    const FAQs = [
        {
          question: "What is custom web, app, software, or desktop app development?",
          answer: "Custom web, app, software, or desktop app development involves creating a unique solution designed specifically for your business. This approach ensures that all features and functionalities are developed to meet your specific needs and goals. These projects are built from the ground up and include various components such as front-end and back-end development, graphic design, and user experience (UX) design."
        },
        {
          question: "Why choose custom solutions for web, app, software, or desktop development?",
          answer: (
            <div className='p-0'>
                <p>Choosing custom solutions ensures your project meets specific needs and stands out from the competition. Unlike pre-made templates, custom development starts from scratch, resulting in a unique solution that fully aligns with your goals.</p>
                <ul className='p'>
                <li className='small'>Brings your ideas to life with a design and functionality that fit your requirements perfectly.</li>
                <li className='small'>Enhances user experience with a focus on what matters most to your audience.</li>
                <li className='small'>Helps you reach a broader audience and attract new customers effectively.</li>
                <li className='small'>Leverages cloud computing for scalability and fast growth.</li>
                <li className='small'>Allows transformation into beautifully designed iOS or Android apps at competitive rates.</li>
                </ul>
            </div>
          )
        },

        {
          question: "Can you help with hosting and domain setup for deployment?",
          answer: "Yes, we handle all aspects of web development, including hosting and domain setup. We offer reliable hosting solutions and assist with selecting the perfect domain name to ensure a smooth and efficient launch of your website."
        },
        {
          question: "Can you manage both small and large-scale web, app, software, or desktop projects?",
          answer: "Absolutely. We have extensive experience handling projects of all sizes, from small-scale websites to large-scale enterprise solutions. Whether you need a simple landing page or a complex software system, our expertise enables us to deliver high-quality solutions that meet your specific business needs."
        },
        {
          question: "Will my web, app, software, or desktop solution be mobile-friendly or cross-platform?",
          answer: "Ensuring that your web, app, software, or desktop solution is mobile-friendly and cross-platform is a top priority. We focus on delivering an exceptional user experience across all devices, including smartphones and tablets, to meet the demands of today’s digital landscape."
        },
        {
          question: "Can you improve the performance and visibility of my web, app, software, or desktop solution?",
          answer: "Yes, we can enhance the performance and visibility of your web, app, software, or desktop solution. We employ effective strategies to optimize your website for better search engine rankings and improved visibility. Our approach integrates performance enhancements with our web development services to ensure your solution reaches its intended audience effectively."
        },
        {
          question: "How will we communicate during the development of web, app, software, or desktop solutions?",
          answer: "Clear and transparent communication is a key part of our remote collaboration process. We use channels like email and project management tools to stay in touch. You will be kept informed and involved throughout every stage of the development process to ensure a smooth and collaborative experience"
        },
        {
          question: "Do you offer content management or management tools for web, app, software, or desktop solutions?",
          answer: "Yes, we offer a range of content management systems and can also develop a custom CMS to suit your requirements. Our goal is to provide you with flexibility and ease in managing your website’s content."
        },
        {
          question: "Can you assist with migration or redesign for web, app, software, or desktop solutions?",
          answer: "Yes, we can assist with migrating your website to a new platform or redesigning your existing site. We ensure a smooth transition and a strategic redesign that enhances your site’s appearance, functionality, and performance."
        },
        {
          question: "Will I be able to update the web, app, software, or desktop solution myself after it’s built?",
          answer: "If you have experience with CMS platforms like WordPress or Shopify, you should be able to update your website's content yourself. For other types of web, app, software, or desktop solutions, we offer support and ongoing maintenance to help you keep everything up to date. We're here to assist with any updates or changes you need to make."
        }
      ];

      const [openIndex, setOpenIndex] = useState(null);
      const answerRefs = useRef([]);
    
      const toggleOpen = (index) => {
        setOpenIndex(openIndex === index ? null : index);
      };
    
      useEffect(() => {
        answerRefs.current.forEach((ref, idx) => {
          if (ref) {
            if (openIndex === idx) {
              ref.style.height = `${ref.scrollHeight}px`;
            } else {
              ref.style.height = '0';
            }
          }
        });
      }, [openIndex]);

    return(
       
            
        <div className="container-fluid p-0">
            <section>
                <div className="home-first-screen-image">
                    <div className="home-text text-dark-blue text-center">
                        <h1 className="fw-bolder home-text-title text-title-screen" style={{fontFamily:"Golos Text, sans-serif"}}>Welcome to <span className="highlight">Sanara IT</span> Solutions</h1>
                        <br />
                        <p className="sub-title">Elevate Your Business with Expert IT Solutions</p>
                        <p className="paragraph">Unlock the full potential of your business with Sanara IT, your trusted partner for comprehensive IT services. From concept to execution, we're here to transform your digital landscape and drive your success forward.</p>   
                        <br />
                        <div>
                        <a href='/contactus'><button type="button" className="btn bg-yellow-gold text-dark-blue text-center get-a-free-quote-btn m-auto btn-labeled rounded-0 fw-bold">Get a Free Quote</button></a>
                        </div>      
                    </div>
                </div>
            </section>
            <section className="container-fluid bg-white">
                    <div className="container pb-5">
                        <div className="row m-0">
                            <div className="col-lg-4">
                                <div className="card py-5 border-0 rounded-0">
                                    <div className="card-body p-0 text-center">
                                        <div className="fw-bolder number-each-screen" style={{color: "#F3B303"}}>200+</div>
                                        <div className="fw-bold multiple-title-each-screen">Projects Completed</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                            <div className="card py-5 border-0 rounded-0">
                                    <div className="card-body p-0 text-center">
                                        <div className="fw-bolder number-each-screen" style={{color: "#F3B303"}}>99%</div>
                                        <div className="fw-bold multiple-title-each-screen">Satisfied Client</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card py-5 border-0 rounded-0">
                                    <div className="card-body p-0 text-center">
                                        <div className="fw-bolder number-each-screen" style={{color: "#F3B303"}}>10+</div>
                                        <div className="fw-bold multiple-title-each-screen">Years Experience</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            <section className="what-we-offer-section">
                <div className="container p-5 custom-container">
                    <h1 className="text-center title-section text-title-screen text-dark-blue">What We Offer</h1>
                    <p className="paragraph px-4 text-center">"Discover our range of solutions to meet your needs. Whether it's expert consulting or specialized services, we're here to support your success. Explore our offerings to find the right fit for you."</p>
                    <br />
                    <div className="row m-0">
                    <div className="col-lg-4 mt-4">
                            <div className="card border-0 rounded-0">
                                <div className="card-body text-center" style={{ placeItems: 'center' }}>
                                    <div className="icon">
                                    <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke="none" strokeWidth="0" fill="#f5f5f5" d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"></path>
                                    </svg>
                                    <FontAwesomeIcon className="text-dark fs-32 iconfontawesome" icon={faCode} />
                                    </div>
                                    <br/>
                                    <p className="offer-title ms-3 fw-medium">Web Design  & Development</p>
                                    <p className="offer-description">e build websites aligned with your goals, delivering solutions that exceed expectations and authentically reflect your brand. Focusing on user experience and the latest technologies, we create impactful digital experiences to drive your business forward.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <div className="card border-0 rounded-0">
                                <div className="card-body text-center" style={{ placeItems: 'center' }}>
                                    <div className="icon">
                                        <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5" d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"></path>
                                        </svg>
                                        <FontAwesomeIcon className="fs-32 iconfontawesome" icon={faGlobe} style={{color: '#ffa76e'}} />
                                    </div>
                                    <br/>
                                    <p className="offer-title ms-3 fw-medium">Search Enging Optimization (SEO)</p>
                                    <p className="offer-description">SEO boosts your website’s visibility and search ranking by improving relevance, authority, and user experience. Through keyword research, page optimization, content creation, and link building, we attract quality traffic and enhance conversions, staying updated with algorithms.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <div className="card border-0 rounded-0">
                                <div className="card-body text-center" style={{ placeItems: 'center' }}>
                                    <div className="icon">
                                        <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5" d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"></path>
                                        </svg>
                                        <FontAwesomeIcon className="fs-32 iconfontawesome" icon={faPenNib} style={{color: '#e80368'}} />
                                    </div>
                                    <br/>
                                    <p className="offer-title ms-3 fw-medium">Graphic Design</p>
                                    <p className="offer-description">Our Graphic Design service brings your vision to life. We create eye-catching designs aligned with your brand and goals, from logos to promotional materials. Each design uniquely represents your identity, leaving a lasting impression. Let's transform your ideas into stunning visuals together!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <div className="card border-0 rounded-0">
                                <div className="card-body text-center" style={{ placeItems: 'center' }}>
                                    <div className="icon">
                                        <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5" d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813"></path>
                                        </svg>
                                        <FontAwesomeIcon className="fs-32 iconfontawesome" icon={faScrewdriverWrench} style={{color: '#ffbb2c'}} />
                                    </div>
                                    <br/>
                                    <p className="offer-title ms-3 fw-medium">Maintenance and Support</p>
                                    <p className="offer-description">Our Website Maintenance and Support service keeps your site secure, updated, and performing optimally. We provide regular updates, prompt support, and bug fixes, allowing you to focus on core business activities while we ensure smooth operation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <div className="card border-0 rounded-0">
                                <div className="card-body text-center" style={{ placeItems: 'center' }}>
                                    <div className="icon">
                                        <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5" d="M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,532.3932930995766,300,532.3542879108572"></path>
                                        </svg>
                                        <FontAwesomeIcon className="fs-32 iconfontawesome" icon={faLaptopCode} style={{color: '#ff5828'}} />
                                    </div>
                                    <br/>
                                    <p className="offer-title ms-3 fw-medium">Hosting & Domain Name Registration</p>
                                    <p className="offer-description">Enjoy reliable web hosting and secure your online presence with our easy Domain Name Registration. With competitive pricing and a smooth process, we ensure seamless online experiences. Trust us for dependable hosting and effortless brand establishment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <div className="card border-0 rounded-0">
                                <div className="card-body text-center" style={{ placeItems: 'center' }}>
                                    <div className="icon">
                                        <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5" d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"></path>
                                        </svg>
                                        <FontAwesomeIcon className="fs-32 iconfontawesome" icon={faMobileScreenButton} style={{color: '#11dbcf'}} />
                                    </div>
                                    <br/>
                                    <p className="offer-title ms-3 fw-medium">Mobile Application Development</p>
                                    <p className="offer-description">We build mobile apps that reflect your brand, exceed expectations, and drive results. Our focus on user experience and advanced technology creates engaging digital solutions. Let us enhance your brand and captivate your audience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
            <section style={{backgroundColor: '#f7f7f7'}}>
                <div className='pt-5 pb-5'>
                    <div className="container text-dark-blue pt-5 pb-5">
                        <div className='row m-0'>
                            <div className='col-lg-6'>
                                <div className='card bg-transparent border-0'>
                                    <div className='card-body'>
                                        <img src='./everything-you-need.png' style={{width: '100%'}} loading="lazy" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='card bg-transparent border-0'>
                                    <div className='card-body'>
                                    <h1 className="title-section text-title-screen text-dark-blue">Everything You Need for Success</h1>
                                    <div>
                                        <ul className='p-0'>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Custom Design and Branding</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> User Experience and Interface Optimization</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Custom Development</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Full Stack Development</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Cross-Platform Integration</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Native App Development</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> API Development and Management</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Robust Security and Comppance</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> DevOps and Continuous Depvery</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Data Analytics and Insights</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Performance Optimization and Scapng</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Ongoing Support and Maintenance</p>
                                            <p className='my-4'><FontAwesomeIcon className="faq-icon text-yellow-gold fs-18" icon={faCircleCheck} /> Search engine optimization (SEO)</p>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="home-second-screen-image">
                    <div className="home-text text-dark-blue text-center">
                        <h1 className="fw-bolder home-text-title text-title-screen text-yellow-gold" style={{fontFamily:"Golos Text, sans-serif"}}>Get Started with Sanara IT</h1>
                        <br />
                        <p className="sub-title text-white">Ready to elevate your business with expert IT solutions? Contact us today to discuss your needs and get started on your project.</p>
                        <br />
                        <div>
                        <NavLink to='/contactus'><button type="button" className="btn bg-yellow-gold text-dark-blue text-center get-a-free-quote-btn m-auto btn-labeled rounded-0 fw-bold">Contact us</button></NavLink>
                        </div>      
                    </div>
                </div>
            </section>
            <section>
                <div className='pt-5 pb-5 px-2'>
                    <div className="text-dark-blue text-center pt-5 pb-5">
                        <h1 className="text-center title-section text-title-screen text-dark-blue">Frequently asked questions</h1>
                        <br />
                        <div className='container'>
                            <ul className='m-0 p-0'>
                                {FAQs.map((faq, index) => (
                                <li key={index} className='list-group-item faq-button bg-white mt-4 rounded fs-32'>
                                    <div className='d-flex justify-content-spacebetween align-items-center p-3'>
                                        <button className='w-100 h-100 text-start border-0 bg-transparent title-faq-answer'
                                        onClick={() => toggleOpen(index)}
                                        >
                                        {faq.question}
                                        </button>
                                        <FontAwesomeIcon className="text-yellow-gold fs-32" icon={faArrowCircleRight} />
                                    </div>
                                    <div
                                    ref={(el) => (answerRefs.current[index] = el)}
                                    className={`faq-answer ${openIndex === index ? 'open' : ''}`}
                                    >
                                    <div className='description-faq-answer px-4' style={{ paddingBottom: '20px' }}>
                                        {faq.answer}
                                    </div>
                                    </div>
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


export default Home;