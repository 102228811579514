import { useLocation } from "react-router-dom";

import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'; // Importing the Facebook icon
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function Header(){

const [isMenuVisible, setIsMenuVisible] = useState(false);
const location = useLocation(); // Get the current path

  const toggleMenu = () => {
    setIsMenuVisible(prevState => !prevState);
  };

  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? 'hidden' : 'auto';
    
    // Clean up effect
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMenuVisible]);

    return(
        <div className="main-header">
            <div className="container-fluid bg-white p-0">
                <div className="header-wrapper bg-dark-blue">
                    <header className="container d-lg-flex d-sm-block">
                        <div>
                            <span className="fs-13 fw-bold contact-info">Message us: <a href="mailto:info@sanarait.com" className="text-white text-decoration-none">info@sanarait.com</a></span>
                            <span className="fs-13 fw-bold contact-info px-3">Call us: <a href="tel:+639629829146" className="text-white text-decoration-none">+63 962 982 9146</a></span>
                        </div>
                        <div>
                            <span className="fs-13 fw-bold">Follow us:</span>

                            <a href="https://www.facebook.com/devcraftsolutions2021" className="text-white" target="_blank"><FontAwesomeIcon className="icons" icon={faPhoneVolume} /></a>
                            <a href="https://www.facebook.com/devcraftsolutions2021" className="text-white" target="_blank"><FontAwesomeIcon className="icons" icon={faFacebookF} /></a>
                            <a href="https://www.facebook.com/devcraftsolutions2021" className="text-white" target="_blank"><FontAwesomeIcon className="icons" icon={faLinkedinIn} /></a>
                        </div>
                    </header>
                </div>
                <div className="nav-wrapper">
                    <div className="container py-3 d-flex justify-content-between align-items-center">
                        <NavLink to='/'><img src="./asdasdas.png" width={200} alt="Sanara IT" title="Sanara IT" loading="lazy" /></NavLink>
                        <nav className="navbar navbar-expand-lg">
                            <span><FontAwesomeIcon className="show-menu text-dark h1 mb-0" icon={faBars} onClick={toggleMenu} />
                            </span>
                            <div className={`collapse-navbar-collapse ${isMenuVisible ? 'visible' : 'hidden'}`} id="navbarSupportedContent">
                                <br />
                                <div className="close-menu py-5 mt-5 text-end px-5">
                                    <FontAwesomeIcon className="text-white h1 mb-0" icon={faXmark} onClick={toggleMenu} />
                                </div>
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                    <a href='/' className={`nav-link ${location.pathname === '/' ? 'active' : 'inactive'} text-dark fw-bold`}>Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${location.pathname === '/aboutus' ? 'active' : 'inactive'} text-dark fw-bold ms-4`} href='/aboutus'>About us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${location.pathname === '/reviews' ? 'active' : 'inactive'} text-dark fw-bold ms-4`} href='/reviews'>Reviews</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${location.pathname === '/team' ? 'active' : 'inactive'} text-dark fw-bold ms-4`} href='/team'>Team</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${location.pathname === '/services' ? 'active' : 'inactive'} text-dark fw-bold ms-4`} href='/services'>Services</a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className={`nav-link ${location.pathname === '/' ? 'active' : 'inactive'} text-dark fw-bold ms-4`} href='/portfolio'>Portfolio</a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a className={`nav-link ${location.pathname === '/contactus' ? 'active' : 'inactive'} text-dark fw-bold ms-4`} href='/contactus'>Contact us</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;