import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faStar } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/fonts/slick.ttf';
import 'slick-carousel/slick/fonts/slick.woff';
import 'slick-carousel/slick/fonts/slick.svg';
import 'slick-carousel/slick/fonts/slick.eot';


function Reviews() {

    const testimonials = [
        { id: 1, image: './reviews/toddmorris999.jpg', name: 'toddmorris999', country: 'United States', text: "Thanks for being so understanding of this project" },
        { id: 2, image: './reviews/asilodollars.jpg', name: 'asilodollars', country: 'Nigeria', text: "He was good and professional, consistently displaying a strong work ethic, excellent skills, and a dedication to delivering high-quality results." },
        { id: 3, image: './reviews/kenjmorford.jpg', name: 'kenjmorford', country: 'United States', text: "He listened to me and was patient enough as we went through the process. He is extremely polite and fast to get you something to look at. True to his word he really will do all the revisions you need to be satisfied. As far as the work goes, I am very impressed! If I get my business really going, I plan on coming back for an upper-level website. I really can't say enough about how good the work he does is. You wont be disapointed." },
        { id: 4, image: './reviews/cooldudxyxy.jpg', name: 'cooldudxyxy', country: 'United States', text: "Exactly what I needed!" },
        { id: 5, image: './reviews/phonoodle.jpg', name: 'phonoodle', country: 'Canada', text: "Made exactly what I wanted. Followed all my instructions. Delivery was quick! Amazing work! Really satisfied. Good man!" },
        { id: 6, image: './reviews/deanwinchest904.jpg', name: 'deanwinchest904', country: 'Canada', text: "Did an incredible job, very patient and respectful. I'd recommend him to everyone!" },
        { id: 7, image: './reviews/alexpartridg671.jpg', name: 'alexpartridg671', country: 'Ireland', text: "Amazing ! great communication" },
        { id: 8, image: './reviews/andrescalfulaf.jpg', name: 'andrescalfulaf', country: 'Chile', text: "Muy buen trabajo" },
        { id: 9, image: './reviews/carlb333.jpg', name: 'carlb333', country: 'United States', text: "Great job!" },
        { id: 10, image: './reviews/tigo007.jpg', name: 'tigo007', country: 'Netherlands', text: "Very happy with the product! Good communication and very friendly." },
        { id: 11, image: './reviews/daysoff_no.jpg', name: 'daysoff_no', country: 'Norway', text: "Nice and fast!" },
        { id: 12, image: './reviews/imunique.jpg', name: 'imunique', country: 'United States', text: "Excellent Fiverr gig! The communication was excellent, and he did exactly what I needed and extremely quickly as well. Above and beyond the call of duty. I highly recommend." },
        { id: 13, image: './reviews/dreamweaver8484.jpg', name: 'dreamweaver8484', country: 'United States', text: "A top seller on Fiverr. I highly recommend him to anyone. He is a hard worker, reliable, educated and delivers on time. He is also very helpful in every manner and will answer any question. Very trustworthy. Thank you for a job well done." },
        { id: 14, image: './reviews/paul1926.jpg', name: 'paul1926', country: 'Philippines', text: "Good communication , Smooth transaction and Job well done . Kudos !" },
        { id: 15, image: './reviews/mrnikmg.jpg', name: 'mrnikmg', country: 'Malaysia', text: "Jet is a good person! Tq" },
        { id: 16, image: './reviews/amirsohel527.jpg', name: 'amirsohel527', country: 'Bangladesh', text: "He did a great job, exactly what I was looking for and he did it very fast. Highly recommend" },
        { id: 17, image: './reviews/kornkamornsornc.jpg', name: 'kornkamornsornc', country: 'Thailand', text: "It was easy to communicate and finalize to start the project" },
        { id: 18, image: './reviews/shoaibmustafa.jpg', name: 'shoaibmustafa', country: 'Finland', text: "He is excellent and super fast. Love his work. I am sure i will order again." },
        { id: 19, image: './reviews/kayomyego.jpg', name: 'kayomyego', country: 'United States', text: "wouldn’t buy from this seller again.didn’t understand the assignment but claim they did and delivered product wasn’t even close to what the assignment goal was" },
      ];

      const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
          result.push(array.slice(i, i + size));
        }
        return result;
      };
    
      const getRandomTestimonials = (testimonials, count) => {
        const shuffled = testimonials.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
      };
    
      const [displayedTestimonials, setDisplayedTestimonials] = React.useState([]);
      React.useEffect(() => {
        setDisplayedTestimonials(getRandomTestimonials(testimonials, 19)); // 9 because 3 per slide
      }, []);
    
      const testimonialsChunks = chunkArray(displayedTestimonials, 3);
    
      
      const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        prevArrow: null,
        nextArrow: null,
      };
      

    return (
        <div>
          <>
            <section>
                <div className="about-first-screen-image">
                    <div className="home-text text-dark-blue text-center">
                        <h1 className="fw-bolder home-text-title text-title-screen text-yellow-gold" style={{fontFamily:"Golos Text, sans-serif"}}>Reviews</h1>
                    </div>
                </div>
            </section>
            <section>
                <div className='pt-5 pb-5'>
                    <div className="container text-dark-blue pt-5 pb-5">
                        <div className='card bg-transparent border-0'>
                            <div className='card-body p-0 w-100'>
                                <table className="w-100">
                                  <tr>
                                    <th colSpan={2} className="border border-dark-blue text-white p-3 bg-dark-blue">Sanara IT Reviews</th>
                                  </tr>
                                  <tr>
                                    <th colSpan={2} className="border border-custom text-dark-blue p-3">
                                      5.0
                                      <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                      <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                      <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                      <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                      <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                      <small style={{ color: '#e62415' }} className="fs-13 fw-normal"> 20 REVIEWS</small>
                                    </th>
                                  </tr>
                                </table>

                              <div className="border border-top-0 border-custom">
                                <Slider {...settings}>
                                  {testimonialsChunks.map((chunk, index) => (
                                    <div key={index} className="slick-slide">
                                      <table className="w-100 ">
                                        {chunk.map(testimonial => (
                                          <tr key={testimonial.name}>
                                            <td>
                                              <div className="px-2 my-2">
                                                <div className="w-100 custom-reviews-box" style={{ boxShadow: '0 0 10px 0 rgba(203,225,231,.85)' }}>
                                                    <div style={{ width: '300px' }} className="border border-custom reviews-left">
                                                      <div className="py-4 px-3 d-flex" style={{ placeItems: 'center' }}>
                                                        <img src={testimonial.image} width={40} height={40} className='rounded-circle' alt={testimonial.name} />
                                                        <div className="px-2">
                                                          <p className="card-text mt-2 m-0"><strong>{testimonial.name}</strong></p>
                                                          <small className="card-text m-0">{testimonial.country}</small>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="border border-custom w-100">
                                                      <div className="py-4 px-3" style={{ placeItems: 'center' }}>
                                                        <div>
                                                          <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                                          <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                                          <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                                          <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                                          <FontAwesomeIcon className="star-icon fs-13" icon={faStar} />
                                                        </div>
                                                        <p className="card-text fs-13 testimonial-text">{testimonial.text}</p>
                                                      </div>
                                                    </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </table>
                                    </div>
                                  ))}
                                </Slider>
                              </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
          </> 
        </div>
    );
}

export default Reviews;
