import React from 'react';

function Team(){
    return(
        <div>
            <>
                <section>
                    <div className="about-first-screen-image">
                        <div className="home-text text-dark-blue text-center">
                            <h1 className="fw-bolder home-text-title text-title-screen text-yellow-gold" style={{fontFamily:"Golos Text, sans-serif"}}>Team</h1>
                        </div>
                    </div>
                </section>
                <section className="what-we-offer-section">
                    <div className="container p-5 custom-container">
                        <h1 className="text-center title-section text-title-screen text-dark-blue">Meet the team</h1>
                        <p className="paragraph px-4 text-center">At Sanara IT, our team's diverse skills and expertise are the foundation of our success. Each member plays a crucial role in shaping our projects and delivering exceptional service. Get to know the dedicated professionals behind our work and see how their collective experience drives our achievements. Discover the individuals who make Sanara IT stand out in the industry!</p>
                        <br />
                        <div className="row m-0">
                        <div className="col-lg-4 mt-4">
                                <div className="card p-0 border-0 rounded-0">
                                    <div className="card p-0 border-0 rounded-0">
                                        <div className="card-body p-0" style={{ placeItems: 'center' }}>
                                            <img src='../team/KeysiSanara.jpg' width={'100%'} />
                                            <br/>
                                            <div className="py-4">
                                                <p className="fs-32 team-title ms-3 fw-medium">Kristine Crisseda Mangareng</p>
                                                <p className="fs-16 ms-3 fw-medium">CEO & Executive Director</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="card p-0 border-0 rounded-0">
                                    <div className="card p-0 border-0 rounded-0">
                                        <div className="card-body p-0" style={{ placeItems: 'center' }}>
                                            <img src='../team/jethro.jpg' width={'100%'} />
                                            <br/>
                                            <div className="py-4">
                                                <p className="team-title ms-3 fw-medium">Jethro Sedoguio</p>
                                                <p className="fs-16 ms-3 fw-medium">Web & App Developer</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="card p-0 border-0 rounded-0">
                                    <div className="card p-0 border-0 rounded-0">
                                        <div className="card-body p-0" style={{ placeItems: 'center' }}>
                                            <img src='../team/troilus.jpg' width={'100%'} />
                                            <br/>
                                            <div className="py-4">
                                                <p className="team-title ms-3 fw-medium">Troilus Sedoguio</p>
                                                <p className="fs-16 ms-3 fw-medium">Web Developer</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="card p-0 border-0 rounded-0">
                                    <div className="card-body p-0" style={{ placeItems: 'center' }}>
                                        <img src='../team/edmar.jpg' width={'100%'} />
                                        <br/>
                                        <div className="py-4">
                                            <p className="team-title ms-3 fw-medium">Edmar</p>
                                            <p className="fs-16 ms-3 fw-medium">Software Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-4">
                                <div className="card p-0 border-0 rounded-0">
                                    <div className="card p-0 border-0 rounded-0">
                                        <div className="card-body p-0" style={{ placeItems: 'center' }}>
                                            <img src='../team/Reynan Yu Labadlabad.jpg' width={'100%'} />
                                            <br/>
                                            <div className="py-4">
                                                <p className="team-title ms-3 fw-medium">Reynan Labadlabad</p>
                                                <p className="fs-16 ms-3 fw-medium">Web Designer</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 mt-4">
                                <div className="card p-0 border-0 rounded-0">
                                    <div className="card p-0 border-0 rounded-0">
                                        <div className="card-body p-0" style={{ placeItems: 'center' }}>
                                            <img src='../team/Dave Libres Anggot.jpg' width={'100%'} />
                                            <br/>
                                            <div className="py-4">
                                                <p className="team-title ms-3 fw-medium">Dave Libres Anggot</p>
                                                <p className="fs-16 ms-3 fw-medium">Wordpress Developer</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        </div>
    )
}

export default Team;