import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import ReCAPTCHA from "react-google-recaptcha";

import Home from './pages/Home';
import Aboutus from './pages/Aboutus';
import Blogs from './pages/Blogs';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Contactus from './pages/Contactus';
import './App.css';
import Reviews from './pages/Reviews';
import Thankyou from './pages/Thankyou';
import Team from './pages/Team';

function App() {
  // const [isVerified, setIsVerified] = useState(() => {
  //   // Check localStorage for verification status
  //   return localStorage.getItem('isVerified') === 'true';
  // });
  // const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);
  // const recaptchaRef = React.createRef();

  // useEffect(() => {
  //   if (!isVerified) {
  //     const loadReCaptchaV3 = () => {
  //       return new Promise((resolve, reject) => {
  //         if (window.grecaptcha) {
  //           resolve();
  //           return;
  //         }
  //         const script = document.createElement('script');
  //         script.src = `https://www.google.com/recaptcha/api.js?render=6LcsKSQqAAAAAGBB1aTGywc5Tkqfsu2VazRUgVwo`;
  //         script.onload = () => {
  //           if (window.grecaptcha) {
  //             window.grecaptcha.ready(() => {
  //               resolve();
  //             });
  //           } else {
  //             reject(new Error('grecaptcha not available'));
  //           }
  //         };
  //         script.onerror = (e) => reject(e);
  //         document.head.appendChild(script);
  //       });
  //     };

  //     loadReCaptchaV3()
  //       .then(() => {
  //         if (window.grecaptcha) {
  //           window.grecaptcha.execute().then(handleRecaptchaV3).catch(error => {
  //             console.error("Error executing reCAPTCHA v3:", error);
  //             setShowRecaptchaV2(true); // Fallback to reCAPTCHA v2 on error
  //           });
  //         } else {
  //           console.error("grecaptcha is not defined.");
  //           setShowRecaptchaV2(true); // Fallback to reCAPTCHA v2
  //         }
  //       })
  //       .catch(err => {
  //         console.error('Error loading reCAPTCHA v3:', err);
  //         setShowRecaptchaV2(true); // Fallback to reCAPTCHA v2
  //       });
  //   }
  // }, [isVerified]);

  // const handleRecaptchaV3 = (token) => {
  //   console.log("reCAPTCHA v3 token:", token);
  //   // Simulate server verification
  //   const isHuman = true; // Replace with actual verification logic

  //   if (isHuman) {
  //     localStorage.setItem('isVerified', 'true');
  //     setIsVerified(true);
  //   } else {
  //     setShowRecaptchaV2(true);
  //   }
  // };

  // const handleRecaptchaV2 = (value) => {
  //   console.log("reCAPTCHA v2 value:", value);
  //   localStorage.setItem('isVerified', 'true');
  //   setIsVerified(true); // Set to true if verification is successful
  // };

  return (
    <BrowserRouter>
      {/* {!isVerified ? (
        <section style={{
          display: 'grid',
          placeItems: 'center',
          width: '100%',
          height: '100vh',
        }}>
          {!showRecaptchaV2 ? (
            <div id="recaptcha-v3-container"></div> // Container for reCAPTCHA v3
          ) : (
            <ReCAPTCHA
              sitekey="6LcmySMqAAAAAL8dVt4srRMHzk_8h6bYAqsfTVKm"
              onChange={handleRecaptchaV2}
              ref={recaptchaRef}
            />
          )}
        </section>
      ) : ( */}
        <>
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/aboutus' element={<Aboutus />} />
            <Route path='/blogs' element={<Blogs />} />
            <Route path='/reviews' element={<Reviews />} />
            <Route path='/team' element={<Team />} />
            <Route path='/services' element={<Services />} />
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/contactus' element={<Contactus />} />
            <Route path='/thankyou' element={<Thankyou />} />
          </Routes>
          <Footer />
        </>
      {/* // )} */}
    </BrowserRouter>
  );
}

export default App;
