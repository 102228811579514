import React from "react";
import { NavLink } from "react-router-dom";

function Thankyou(){
    return(
        <div className="text-center py-5 my-5" style={{height: '100vh'}}>
            <>
            <section className="py-5 my-5">
                <h1 className="fw-normal fs-32">Your inquiry has been successfully submitted.</h1>
                <br />
                <h1 className="fw-normal fs-32">A Sanara IT representative will contact you shortly.</h1>
                <br /><br /><br /><br />
                <a href='/'><button type="button" class="btn bg-yellow-gold text-dark-blue text-center get-a-free-quote-btn m-auto btn-labeled rounded-0 fw-medium">Home</button></a>    
            </section>
            </>
        </div>
    )
}

export default Thankyou;