import React, { useState } from "react";

function Aboutus() {
    
    const image = {
        alt: "Description of the image",
        src: "./d647fe2faa2abdb24f5bc2d316a74eba.jpg",
        height: "auto",
        width: "100%"
    };
    const image2 = {
        alt: "Description of the image",
        src: "./6859701c58db013f3e2213185f339b17.jpg",
        height: "auto",
        width: "100%"
    };

    return (
        <div>
            
                <>
                    <section>
                        <div className="about-first-screen-image">
                            <div className="home-text text-dark-blue text-center">
                                <h1 className="fw-bolder home-text-title text-title-screen text-yellow-gold" style={{fontFamily:"Golos Text, sans-serif"}}>About Us</h1>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container p-5 custom-container">
                            <h1 className="title-section text-title-screen text-dark-blue">Who We Are</h1>
                            <p className="paragraph py-3">Welcome to Sanara IT, your trusted partner in comprehensive IT solutions. With a steadfast commitment to excellence, we specialize in transforming businesses through innovative technology and exceptional service. Our team of experienced professionals brings together a wealth of expertise across various domains, ensuring we deliver tailored solutions that meet your unique needs.</p>
                            <br />
                        </div>
                    </section>
                    <section>
                        <div className='pb-5'>
                            <div className="container text-dark-blue pt-5 pb-5">
                                <div className='row m-0'>
                                    <div className='col-lg-6 col-sm-12'>
                                        <div className='card bg-transparent border-0'>
                                            <div className='card-body'>
                                                <h1 className="title-section text-title-screen text-dark-blue">Our Vision</h1>
                                                <p className="paragraph py-3">To be a global leader in IT solutions, recognized for our innovation, customer-centric approach, and commitment to excellence. We aspire to empower businesses worldwide by leveraging technology to unlock new opportunities, drive growth, and create lasting value.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-12'>
                                        <div className='card bg-transparent border-0'>
                                            <div className='card-body'>
                                                <img
                                                    alt={image.alt}
                                                    height={image.height}
                                                    src={image.src}
                                                    width={image.width}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className='pt-5 pb-5'>
                            <div className="container text-dark-blue pt-5 pb-5">
                                <div className='row m-0'>
                                    <div className='col-lg-6 col-sm-12 mt-4'>
                                        <div className='card bg-transparent border-0'>
                                            <div className='card-body'>
                                                <img
                                                    alt={image2.alt}
                                                    height={image2.height}
                                                    src={image2.src}
                                                    width={image2.width}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-12 mt-4'>
                                        <div className='card bg-transparent border-0'>
                                            <div className='card-body'>
                                                <h1 className="title-section text-title-screen text-dark-blue">Our Mission</h1>
                                                <p className="paragraph py-3">At Sanara IT, our mission is to empower businesses with cutting-edge IT solutions that drive success and innovation. We strive to be at the forefront of technological advancements, offering services that enhance efficiency, security, and growth for our clients. Our goal is to build long-lasting partnerships based on trust, integrity, and mutual success.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container p-5 custom-container">
                            <h1 className="title-section text-title-screen text-dark-blue">Our Values</h1>
                            <br />
                            <ul className=''>
                                <li className='my-4'><b>Excellence:</b> We are committed to delivering the highest quality services and solutions.</li>
                                <li className='my-4'><b>Innovation:</b> We embrace new technologies and approaches to stay ahead in the industry.</li>
                                <li className='my-4'><b>Integrity:</b> We build trust through transparency, honesty, and ethical practices.</li>
                                <li className='my-4'><b>Customer Focus:</b> Our clients are at the heart of everything we do. We listen, understand, and tailor our services to meet their needs.</li>
                                <li className='my-4'><b>Collaboration:</b> We believe in the power of teamwork, both within our company and with our clients.</li>
                            </ul>
                        </div>
                    </section>
                    <section>
                        <div className="container p-5 custom-container">
                            <h1 className="title-section text-title-screen text-dark-blue">Why Choose Us</h1>
                            <br />
                            <ul className=''>
                                <li className='my-4'><b>Proven Expertise:</b> We have a track record of successfully delivering complex IT projects across various industries.</li>
                                <li className='my-4'><b>Client-Centric Approach:</b> We prioritize our clients' needs and work closely with them to achieve their business goals.</li>
                                <li className='my-4'><b>Comprehensive Solutions:</b> We offer end-to-end IT services, ensuring all your technology needs are met under one roof.</li>
                                <li className='my-4'><b>Innovative Thinking:</b> We leverage the latest technologies to provide forward-thinking solutions.</li>
                                <li className='my-4'><b>Reliable Support:</b> Our support team is always available to assist you, ensuring your systems are always up and running.</li>
                            </ul>
                        </div>
                    </section>
                </>
            
        </div>
    );
}

export default Aboutus;
