import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';


function Contactus() {
  const [messageState, messageSetState] = useState('');
  const [errors, setErrors] = useState({});
  const form = useRef();
  const navigate = useNavigate(); // Initialize useNavigate


  const validateForm = () => {
    const errors = {};
    const formElements = form.current.elements;

    if (!formElements.from_name.value) {
      errors.from_name = 'This is a required field.';
    }
    if (!formElements.from_email.value) {
      errors.from_email = 'This is a required field.';
    }
    if (!formElements.from_phone.value) {
      errors.from_phone = 'This is a required field.';
    }
    
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs
        .sendForm('service_8h11sxe', 'template_qi45jgs', form.current, {
          publicKey: 'uLMo5UPr9LC8Iea77',
        })
        .then(
          () => {
            navigate('/thankyou');
            window.location.reload();
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    } else {
      messageSetState('');
    }
  };

  return (
    <div>
      <section>
        <div className="about-first-screen-image">
          <div className="home-text text-dark-blue text-center">
            <h1
              className="fw-bolder home-text-title text-title-screen text-yellow-gold"
              style={{ fontFamily: 'Golos Text, sans-serif' }}
            >
              Contact Us
            </h1>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: '#f9fafb' }}>
        <div className="pt-5 pb-5">
          <div className="container text-dark-blue pt-5 pb-5">
            <div className="row m-0">
              <div className="col-lg-6 col-sm-12 mt-4">
                <div className="card bg-transparent border-0">
                  <div className="card-body">
                    <h1
                      className="fw-bolder home-text-title text-title-screen text-dark-blue"
                      style={{ fontFamily: 'Golos Text, sans-serif' }}
                    >
                      How Can We Help You?
                    </h1>
                    <p className="paragraph py-3">
                      Every business is different. We customize our approach to
                      match your specific needs and goals for progress.
                    </p>
                    <div>
                      <p className="paragraph text-dark my-0 fw-bold">Visit Us</p>
                      <p className="paragraph mb-0">
                        0006 LT BLOCK 40H ST PHASE 3 E2, Dagat-Dagatan Ave, na,
                        Malabon, 1472 Metro Manila
                      </p>
                      <br />
                      <p className="paragraph text-dark my-0 fw-bold">
                        Connect With Us
                      </p>
                      <p className="paragraph mb-0">(+63) 962 982 9146</p>
                      <br />
                      <p className="paragraph text-dark my-0 fw-bold">Reach Us</p>
                      <p className="paragraph">
                        <a href="mailto:info@sanarait.com" className="paragraph">
                          info@sanarait.com
                        </a>
                      </p>
                    </div>
                    <br />
                    <div style={{ width: '100%', height: '300px' }}>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12683.046089531893!2d120.9620215!3d14.6542216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b5d3677e5bbb%3A0x64a15a80a416769c!2sSanara%20IT!5e0!3m2!1sen!2sus!4v1629476635082"
                        frameBorder="0"
                        style={{ border: '0', width: '100%', height: '100%' }}
                        allowFullScreen
                        aria-hidden="false"
                        tabIndex="0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 mt-4">
                <div className="card bg-transparent border-0">
                  <div className="card-body">
                    <form ref={form} onSubmit={sendEmail}>
                      <div>
                        <label className="fw-bold">Fullname*</label>
                        <input
                          type="text"
                          name="from_name"
                          className="form-control shadow-sm border-0 py-3 my-2"
                          placeholder=""
                        />
                        {errors.from_name && (
                          <div className="text-danger">{errors.from_name}</div>
                        )}
                      </div>
                      <br />
                      <div>
                        <label className="fw-bold">Email*</label>
                        <input
                          type="email"
                          name="from_email"
                          className="form-control shadow-sm border-0 py-3 my-2"
                          placeholder=""
                        />
                        {errors.from_email && (
                          <div className="text-danger">{errors.from_email}</div>
                        )}
                      </div>
                      <br />
                      <div>
                        <label className="fw-bold">Phone*</label>
                        <input
                          type="tel"
                          name="from_phone"
                          className="form-control shadow-sm border-0 py-3 my-2"
                          placeholder=""
                        />
                        {errors.from_phone && (
                          <div className="text-danger">{errors.from_phone}</div>
                        )}
                      </div>
                      <br />
                      <div>
                        <label className="fw-bold">How can we help?</label>
                        <textarea
                          name="message"
                          className="form-control shadow-sm border-0 py-3 my-2"
                          rows={8}
                          placeholder=""
                        />
                      </div>
                      <br />
                      <p className="text-success fw-medium">{messageState}</p>
                      <div>
                        <button
                          type="submit"
                          className="btn bg-yellow-gold text-white text-center contact-us-btn m-auto btn-labeled rounded-0 fw-bold"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contactus;
