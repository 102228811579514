import { NavLink } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'; // Importing the Facebook icon
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function Footer(){

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };
    return(
        <div className="footer main-header bg-dark-blue px-0 pb-0">
            <br />
            <div className="container text-white p-5 pb-0 m-auto">
                <div className="pb-5 text-center">
                    <h1 className="w-100 m-auto">Prepared to accelerate your journey with premier IT talent?</h1>
                    <br /><br />
                    <h4>Discover if we're the right fit for each other.</h4>
                    <br /><br />
                    <div>
                    <a href='/contactus'><button type="button" className="btn bg-yellow-gold text-dark-blue get-a-free-quote-btn m-auto btn-labeled rounded-0 fw-bold">Kickstart your projects</button></a>
                    </div>      
                </div>
                <hr/>
                <div className="pt-5 pb-5">
                    <div className="row m-0">
                        <div className="col-lg-3 col-md-6 col-sm-12 mt-4">
                            <h4>About us</h4>
                            <div>
                                <p><a href="/reviews" className="text-white text-decoration-none">Reviews</a></p>
                                <p><a href="/team" className="text-white text-decoration-none">Meet the Team</a></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mt-4">
                            <h4>Get in touch</h4>
                            <div>
                                <p><a href="/contactus" className="text-white text-decoration-none">Get a Proposal</a></p>
                                <p><a href="/contactus" className="text-white text-decoration-none">Contact us</a></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mt-4">
                            <h4>Our Work</h4>
                            <div>
                                <p><a href="/services" className="text-white text-decoration-none">Web Development</a></p>
                                <p><a href="/services" className="text-white text-decoration-none">Application Development</a></p>
                                <p><a href="/services" className="text-white text-decoration-none">eCommerce</a></p>
                                <p><a href="/services" className="text-white text-decoration-none">UI & UX Design</a></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mt-4">
                            <div>
                                <a href="https://www.facebook.com/devcraftsolutions2021" className="text-white m-3" target="_blank"><FontAwesomeIcon className="icons" icon={faPhoneVolume} /></a>
                                <a href="https://www.facebook.com/devcraftsolutions2021" className="text-white m-3" target="_blank"><FontAwesomeIcon className="icons" icon={faFacebookF} /></a>
                                <a href="https://www.facebook.com/devcraftsolutions2021" className="text-white m-3" target="_blank"><FontAwesomeIcon className="icons" icon={faLinkedinIn} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="border-white m-0" />
                <div className="text-center text-white py-4">
                    © 2024 Sanara IT. All Rights Reserved.
                </div>
        </div>
    )
}

export default Footer;